// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_t3 d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_t4 d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_t5 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_t6 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_t7 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_t8 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_t9 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_rL q_rL";
export var heroExceptionNormal = "w_rM q_rM";
export var heroExceptionLarge = "w_rN q_rN";
export var Title1Small = "w_rq q_rq q_qW q_qX";
export var Title1Normal = "w_rr q_rr q_qW q_qY";
export var Title1Large = "w_rs q_rs q_qW q_qZ";
export var BodySmall = "w_rF q_rF q_qW q_rd";
export var BodyNormal = "w_rG q_rG q_qW q_rf";
export var BodyLarge = "w_rH q_rH q_qW q_rg";