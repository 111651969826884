// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_vF";
export var lbContainerInner = "F_vG";
export var movingForwards = "F_vH";
export var movingForwardsOther = "F_vJ";
export var movingBackwards = "F_vK";
export var movingBackwardsOther = "F_vL";
export var lbImage = "F_vM";
export var lbOtherImage = "F_vN";
export var prevButton = "F_vP";
export var nextButton = "F_vQ";
export var closing = "F_vR";
export var appear = "F_vS";